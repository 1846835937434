// client/src/App.js

import "./App.css";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useNavigate,
  Link,
} from "react-router-dom";
import { useEffect, useState, createContext } from "react";
import CreateListing from "./pages/createListing/CreateListing";
import Listings from "./pages/listings/Listings";
import ListingDetails from "./pages/listingDetails/ListingDetails";
import Login from "./pages/authentication/Login";
import Signup from "./pages/authentication/Signup";
import ListingFilter from "./pages/listings/ListingFilter";
import ProtectedRoute from "./components/ProtectedRoute";
import Navbar from "./components/NavBar";
import ProfilePage from "./pages/profile/ProfilePage";
import ForgotPasswordForm from "./components/ForgotPasswordForm";
import EditListing from "./pages/editListing/EditListing";
import axiosInstance from "./utils/axiosInstance";
import LegalLinks from "./pages/legalLinks/LegalLinks";
import Contact from "./pages/contact/Contact";

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(!!localStorage.getItem("token"));
  const [makesAndModels, setMakesAndModels] = useState({});
  const handleSignOut = () => {
    localStorage.removeItem("token");
    setIsLoggedIn(false);
  };

  useEffect(() => {
    const getMakesAndModels = async () => {
      let res = await axiosInstance.get("/makesAndModels");
      setMakesAndModels(res.data);
    };

    getMakesAndModels();
  }, []);

  
  return (
    <Router>
      <div className="App">
        <Navbar isLoggedIn={isLoggedIn} handleSignOut={handleSignOut} />
        <Routes>
          <Route
            path="/create-listing"
            element={
              <CreateListing
                isLoggedIn={isLoggedIn}
                makesAndModels={makesAndModels}
              />
            }
          />
          <Route
            path="/listings"
            element={<Listings makesAndModels={makesAndModels} />}
          />
          <Route
            path="/listings/:listingId"
            element={<ListingDetails isLoggedIn={isLoggedIn} />}
          />
          <Route
            path="/edit/:listingId"
            element={
              <EditListing
                isLoggedIn={isLoggedIn}
                makesAndModels={makesAndModels}
              />
            }
          />
          <Route
            path="/pog"
            element={
              <ProtectedRoute
                component={ListingFilter}
                isLoggedIn={isLoggedIn}
              />
            }
          />
          <Route
            path="/login"
            element={
              <Login setIsLoggedIn={setIsLoggedIn} isLoggedIn={isLoggedIn} />
            }
          />
          <Route path="/signup" element={<Signup />} />
          <Route
            path="/profilePage"
            element={<ProfilePage isLoggedIn={isLoggedIn} />}
          />
          <Route path="/forgotPassword" element={<ForgotPasswordForm />} />
          <Route path="/legal" element={<LegalLinks />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="*" element={<Navigate to="/listings" />} />
        </Routes>
       
      </div>
    </Router>
  );
}

export default App;
