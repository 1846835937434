import React, { useState } from 'react';
import './carousel.css'
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';

const Carousel = ({ images }) => {
    const [display, setDisplay] = useState(0);

    const handlePrev = () => {
      setDisplay((prevIndex) =>
        prevIndex === 0 ? images.length - 1 : prevIndex - 1
      );
    };
  
    const handleNext = () => {
      setDisplay((prevIndex) =>
        prevIndex === images.length - 1 ? 0 : prevIndex + 1
      );
    };
  
    return (
      <div className="carousel">
        <button className="left" onClick={handlePrev}>
          <IoIosArrowBack />
        </button>
        {images.map((image, index) => (
          <img
            key={index}
            src={image}
            alt={`Slide ${index}`}
            className={index === display ? "": 'hideImage'}
          />
        ))}
        <button className="right" onClick={handleNext}>
          <IoIosArrowForward />
        </button>
      </div>
    );
  };

export default Carousel;


