import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import axiosInstance from "../../utils/axiosInstance.js";
import EditPartsCarListing from "./EditPartsCarListing.js";
import EditCompleteCarListing from "./EditCompleteCar.js";
import EditSinglePartListing from "./EditSinglePart.js";
const EditListing = ({ isLoggedIn, makesAndModels }) => {
  const [listing, setListing] = useState({});
  const [isOwner, setIsOwner] = useState(false)
  let { listingId } = useParams();

  useEffect(() => {
    const fetchData = async () => {
      let res = await axiosInstance.get(`/listings/${listingId}`);
      console.log(res.data);
      setListing(res.data.listing);
      setIsOwner(res.data.isOwner)
    };
    fetchData();
  }, [listingId]);
   
  return makesAndModels.ACURA ? (isOwner ? (
    <div>

      {listing.type === "partsCar" ? <EditPartsCarListing listing={listing} makesAndModels={makesAndModels}/> : ""}
      {listing.type === "completeCar" ? <EditCompleteCarListing listing={listing} makesAndModels={makesAndModels}/> : ""}
      {listing.type === "singlePart" ? <EditSinglePartListing listing={listing} makesAndModels={makesAndModels}/> : ""}
    </div>
  ) : (
    <div>
      <p>Please <Link to="/login">Login</Link> or <Link to="/signup">Signup</Link> to edit a listing</p>
    </div>
  )) : "...loading";
};

export default EditListing;
