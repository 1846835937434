import React from "react";

const LegalLinks = () => {
  return (
    <div>
        <h2>Legal Document Links</h2>
        <ul>
        <li><a href="https://app.termly.io/document/privacy-policy/5877d090-0d73-4c6e-9331-8f81f9a7e147">Privacy Policy</a></li>
        <li><a href="https://app.termly.io/document/terms-of-service/df5cb76d-40ae-45c2-aa39-71a3b60adff4">Terms Of Service</a></li>
        <li><a href="https://app.termly.io/document/cookie-policy/aeef30c0-868c-4006-ad08-0deba6882a9e">Cookie Policy</a></li>
        <li><a href="https://app.termly.io/document/disclaimer/ac8d7832-a958-40d6-98f2-8dd8f911011e">Disclaimer</a></li>
        <li><a href="https://app.termly.io/document/acceptable-use/1ad1fc02-143a-464d-83eb-280248ae92ac">Acceptable Use Policy</a></li>
        </ul>
        
    </div>
  );
};

export default LegalLinks;