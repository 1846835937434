import React, { useState } from 'react';
import axios from 'axios';
import axiosInstance from '../utils/axiosInstance';
import '../pages/authentication/Signup.css' 
import { useNavigate } from 'react-router-dom';

const ForgotPasswordForm = () => {
    const [email, setEmail] = useState('');
    const [resetToken, setResetToken] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [message, setMessage] = useState('');
    const [tokenSent, setTokenSent] = useState(false);
    const navigate = useNavigate();

    const handleForgotPassword = async () => {
        try {
            const response = await axiosInstance.post('/user/forgotPassword', { email });
            setTokenSent(response.data.tokenSent);
            setMessage('Reset token sent to your email');
        } catch (error) {
            console.error('Forgot password failed:', error.response.data.error);
            setMessage(error.response.data.error);
        }
    };

    const handleResetPassword = async () => {
        try {
            await axiosInstance.post('/user/resetPassword', { resetToken, newPassword });
            alert('Password reset successfully');
            navigate('/login')
        } catch (error) {
            console.error('Reset password failed:', error.response.data.error);
            setMessage(error.response.data.error);

        }
    };

    return (
        <div className="forgot-pass">
            <div className='recoveryEmail'><h3>Recovery Email:</h3></div>
            <br />
            <input className="inputBox" type="email" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} required />
            <br />
            <div className='forgot-pass-submit'> <button onClick={handleForgotPassword}>Send Email</button>
            </div>
            {tokenSent && (
                <div>
                    <input type="text" placeholder="Reset Token" value={resetToken} onChange={(e) => setResetToken(e.target.value)} required/>
                    <input type="password" placeholder="New Password" value={newPassword} onChange={(e) => setNewPassword(e.target.value)} required />
                    <button onClick={handleResetPassword}>Reset Password</button>
                </div>
            )}
            {message && <p>{message}</p>}
        </div>
    );
};

export default ForgotPasswordForm;