import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom'; 
import axiosInstance from '../../utils/axiosInstance';
import ListingCard from '../listings/ListingCard';
import './profilePage.css'


const UsersListings = () => {
    const [usersListings, setUsersListings] = useState([]);

    useEffect(() => {
        const getUsersListings = async() => {
            try{
                let res = await axiosInstance.get("/listings/usersListings");
                setUsersListings(res.data)
            } catch (e) {
                console.log('something went wrong')
            }    
        }

        getUsersListings()
    },[])

  return (
    <div className='yourListings'>
    {usersListings.length > 0 ? usersListings.map((listing,index) => {
          return  <ListingCard key={index} listing={listing} />
        }) : <p>It looks like you do not have any active listings. Maybe you should <Link to="/create-listing">create one</Link></p>}
    </div>
  );
};

export default UsersListings;