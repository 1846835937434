import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom'; 
import axiosInstance from '../../utils/axiosInstance';
import ForgotPasswordForm from '../../components/ForgotPasswordForm';
import './profilePage.css'


const AccountInfo = (props) => {
    const [email, setEmail] = useState('');
    const [resetToken, setResetToken] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [message, setMessage] = useState('');
    const [emailInput, setEmailInput] = useState('');
    const [usernameInput, setUsernameInput] = useState('')

    useEffect(() => {
        setEmailInput(props.userInfo.email)
        setUsernameInput(props.userInfo.username)
    }, [props])

    const handleForgotPassword = async () => {
        try {
            const response = await axiosInstance.post('/user/forgotPassword', { email });
            setResetToken(response.data.resetToken);
            setMessage('Reset token sent to your email');
        } catch (error) {
            setMessage(error.response.data.error);
        }
    };

    const handleResetPassword = async () => {
        try {
            await axiosInstance.post('/user/resetPassword', { resetToken, newPassword });
            setMessage('Password reset successfully');
        } catch (error) {
            
            setMessage(error.response.data.error);
        }
    };
   
    const handleUsernameSubmit = async (e) => {
        try {
             await axiosInstance.put('/user/updateUser', {username: usernameInput});
        } catch (e) {
            console.log('something went wrong')
        }
    }

    const handleEmailSubmit = async (e) => {
        try {
             await axiosInstance.put('/user/updateUser', {email: emailInput});
        } catch (e) {
            console.log('something went wrong')
        }
    }

    const handleEmailChange = async (e) => {
         setEmailInput(e.target.value);
    }

    const handleUsernameChange = async (e) => {
        setUsernameInput(e.target.value);
    }

    

  return (
    <div>
    <p>Username: <br/><input className='inputBoxProfile' type='text' value={usernameInput} onChange={handleUsernameChange}/> <br/> <button onClick={handleUsernameSubmit}>Change Username</button></p>
    
    <p>Email: <br/><input className='inputBoxProfile' type='text' value={emailInput} onChange={handleEmailChange} /> <br/> <button onClick={handleEmailSubmit}>Change Email</button></p>
    <div>
            <p>Reset Password:</p>
            <input className='inputBoxProfile' type="email" placeholder="Email" value={''} onChange={(e) => setEmail(e.target.value)} required />
            <br/>
            <button onClick={handleForgotPassword}>Get Reset Password Email</button>
            {resetToken && (
                <div>
                    <input type="text" placeholder="Reset Token" value={resetToken} readOnly />
                    <input type="password" placeholder="New Password" value={newPassword} onChange={(e) => setNewPassword(e.target.value)} required />
                    <button onClick={handleResetPassword}>Reset Password</button>
                </div>
            )}
            {message && <p>{message}</p>}
        </div>
    </div>
  );
};

export default AccountInfo;