const categories = {
  Engine: [
    "Engine (complete)",
    "Engine Block",
    "Engine Head",
    "Coolant Hose",
    "Water Pump",
    "Thermostat",
    "Engine Gasket",
    "Radiator",
    "Turbocharger",
    "Intercooler",
    "Exhaust Manifold",
    "Catalytic Converter",
    "Exhaust",
    "Muffler",
    "O2 Sensor",
    "Intake Manifold",
    "Carburator",
    "Fuel Injector",
    "Fuel Rail",
    "Fuel Pump",
    "Starter Motor",
    "Flywheel",
    "Oil Pan",
    "Internals",
    "Belt/Pulley",
    "Valve Cover",
    "Distributor",
    "Spark Plug",
    "Spark Plug Wire",
    "Coil Pack",
  ],
  Drivetrain: [
    "Transmission",
    "Drive Shaft",
    "Differential",
    "Axle",
    "Wheel Hub",
    "Clutch",
    "Brake Pad",
    "Brake Rotor",
    "Brake Caliper",
    "Brake Drum",
    "Brake Line",
    "Wheel Lug/Stud",
    "Wheels & Tires",
    "Drivetrain Gasket",
    "Suspension",
  ],
  Interior: [
    "Drivers Seat",
    "Passengers Seat",
    "Rear Seat",
    "Carpet",
    "Plastics",
    "Dash",
    "Gauge Cluster",
    "Shifter",
    "Shift Knob",
    "Seat Belt",
    "Steering Wheel",
    "Door Card",
    "Headliner",
    "Window/Door Lock",
  ],
  Exterior: [
    "Fender",
    "Hood",
    "Trunk",
    "Front Bumper",
    "Rear Bumper",
    "Door",
    "Body Kit",
    "Side Skirt",
    "Side Mirror",
    "Glass",
    "Head Light",
    "Tail Light",
    "Fog Light",
    "Auxillary Light",
    "Spoiler",
    "Splitter",
    "Diffuser",
    "Roof Rack",
    "Cargo Box",
    "Towing/Hitch",
  ],
  Electrical: [
    "ECU (Computer)",
    "Sensor",
    "Switch",
    "Relay",
    "Wiring Harness",
    "Idle Air Control",
    "Ignition Control Module",
    "Bulb",
    "Alternator",
    "Battery",
  ],
  Other: [
    "Other"
  ]
};

export default categories;
