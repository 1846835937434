import { useCallback, useEffect, useState } from "react";
import ListingCard from "./ListingCard";
import "./listing.css";
import SearchBar from "../../components/SearchBar";
import axiosInstance from "../../utils/axiosInstance";
import ListingFilter from "./ListingFilter";
const Listings = ({ makesAndModels }) => {
  const [listings, setListings] = useState([]);
  const [allListings, setAllListings] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      let res = await axiosInstance.get("/listings");

      setListings(res.data);
      setAllListings(res.data);
    };

    fetchData();
  }, []);

  const searchData = async (searchObj) => {
    let queryString = `zipcode=${searchObj.zipcode}&distance=${searchObj.distance}`;

    let res = await axiosInstance.get(`/listings/search?${queryString}`);

    setListings(res.data);
    setAllListings(res.data);
  };

  const handleFilterListings = useCallback(
    (
      makes,
      models,
      startYear,
      endYear,
      minPrice,
      maxPrice,
      mainCategory,
      subCategory
    ) => {
      let filteredListings = [...allListings];
      console.log('start filter', filteredListings);
      if (makes.length > 0) {
        filteredListings = filteredListings.filter((listing) => {
          return makes.includes(listing.make);
        });
      }

      if (models.length > 0) {
        filteredListings = filteredListings.filter((listing) => {
          return models.includes(listing.model);
        });
      }

      if (startYear !== 0) {
        filteredListings = filteredListings.filter((listing) => {
          return listing.year >= startYear;
        });
      }

      if (endYear !== 0) {
        filteredListings = filteredListings.filter((listing) => {
          return listing.year <= endYear;
        });
      }

      if (minPrice > 0) {
        filteredListings = filteredListings.filter((listing) => {
          return listing.price >= minPrice;
        });
      }

      if (maxPrice > 0) {
        filteredListings = filteredListings.filter((listing) => {
          return listing.price <= maxPrice;
        });
      }

      if (mainCategory.length > 0) {
        filteredListings = filteredListings.filter((listing) => {
          let passed = false;
          listing.parts.forEach((part) => {
            if (mainCategory.includes(part.mainCategory)) {
              passed = true;
            }
          });
          return passed;
        });
      }

      if (subCategory.length > 0) {
        filteredListings = filteredListings.filter((listing) => {
          let passed = false;
          listing.parts.forEach((part) => {
            if (subCategory.includes(part.subCategory)) {
              passed = true;
            }
          });
          return passed;
        });
      }

      console.log('end filter', filteredListings)

      setListings(filteredListings);
      //save filters code
      console.log("saving filters", JSON.stringify(makes));
      sessionStorage.setItem("makes", JSON.stringify(makes));
      sessionStorage.setItem("models", JSON.stringify(models));
      sessionStorage.setItem("startYear", JSON.stringify(startYear));
      sessionStorage.setItem("endYear", JSON.stringify(endYear));
      sessionStorage.setItem("minPrice", JSON.stringify(minPrice));
      sessionStorage.setItem("maxPrice", JSON.stringify(maxPrice));
      sessionStorage.setItem("mainCategory", JSON.stringify(mainCategory));
      sessionStorage.setItem("subCategory", JSON.stringify(subCategory));
    },[allListings]
  );
  return (
    <>
      <SearchBar onSearch={searchData} />
      <div className="listings-main">
        <ListingFilter
          handleFilterListings={handleFilterListings}
          makesAndModels={makesAndModels}
        />
        <div className="listings">
          {listings.length === 0 ? <p>No Listings Found...</p> : ""}
          {listings
            ? listings.map((listing, index) => {
                return <ListingCard key={index} listing={listing} />;
              })
            : "Loading"}
        </div>
      </div>
    </>
  );
};

export default Listings;
