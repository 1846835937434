// client/src/pages/home/index.js
import { useEffect, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import axiosInstance from '../../utils/axiosInstance';
import AccountInfo from './AccountInfo';
import UsersListings from './YourListings';
import SavedListings from './SavedListings';
import './profilePage.css'



const ProfilePage = ({ isLoggedIn }) => {
  const [view, setView] = useState("accountInfo");
  const [userInfo, setUserInfo] = useState({})
  useEffect(() => {

    const getUserInfo = async () => {
      const res = await axiosInstance.get('/user/userInfo')
      let user = res.data
      setUserInfo(user);
    }

    getUserInfo()
  }, [])
  return isLoggedIn ? (

    <div className='profilePage'>
      <h1>Welcome back, {userInfo.username}!</h1>
      <br />
      <div className='selectOption'>
        <button onClick={() => { setView('accountInfo') }}>Account Info</button>
        <button onClick={() => { setView('yourListing') }}>Your Listings</button>
        <button onClick={() => { setView('savedPosts') }}>Saved Posts</button>
      </div>
      <div>

        {view === 'accountInfo' ? <AccountInfo userInfo={userInfo} /> : ""}
        {view === 'yourListing' ? <UsersListings /> : ""}
        {view === 'savedPosts' ? <SavedListings savedListings={userInfo.savedListings} /> : ""}
      </div>

    </div>
  ) : <div>
    <p>Please <Link to="/login">Login</Link> or <Link to="/signup">Signup</Link> to view your profile</p>
  </div>;
};

export default ProfilePage;