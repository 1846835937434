import React, { useState } from "react";
import FileUpload from "../../components/FileUpload";
import axiosInstance from "../../utils/axiosInstance";

import partCategories from "../../FormOptionsJson/categories.js";
import years from "../../FormOptionsJson/years";
import { useNavigate } from "react-router-dom";
import "./createListing.css";

const CreatePartsCarListing = ({ makesAndModels, userEmail }) => {
  const [car, setCar] = useState({
    title: "",
    description: "",
    location: "",
    parts: [],
    pictures: [],
    price: 1234,
    make: Object.keys(makesAndModels).sort()[0],
    model: makesAndModels["ACURA"][0],
    year: years[-1],
    type: "partsCar",
    contactEmail: userEmail,
  });
  const navigate = useNavigate();
  const [parts, setParts] = useState([
    {
      name: "",
      price: 123,
      description: "",
      pictures: [],
      mainCategory: Object.keys(partCategories)[0],
      subCategory: partCategories[Object.keys(partCategories)[0]][0],
    },
  ]);

  const handleCarInputChange = (e) => {
    if (e.target.name === "make") {
      setCar({
        ...car,
        [e.target.name]: e.target.value,
        model: makesAndModels[e.target.value][0],
      });
    } else {
      setCar({
        ...car,
        [e.target.name]: e.target.value,
      });
    }
  };

  const handlePartInputChange = (e, index) => {
    const newParts = parts.map((part, i) => {
      if (i !== index) return part;
      if (e.target.name !== "mainCategory") {
        return { ...part, [e.target.name]: e.target.value };
      } else {
        return {
          ...part,
          ...{[e.target.name]: e.target.value,
          subCategory: partCategories[e.target.value][0]},
        };
      }
    });
    setParts(newParts);
  };

  const addPart = () => {
    setParts([
      ...parts,
      {
        name: "",
        price: 123,
        description: "",
        mainCategory: Object.keys(partCategories)[0],
        subCategory: partCategories[Object.keys(partCategories)[0]][0],
      },
    ]);
  };

  const removePart = (index) => {
    parts.splice(index, 1);

    setParts([...parts]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setCar({ ...car, parts });
    // You can call an API here to save the car object in your database
    let res = await axiosInstance.post("/listings", { ...car, parts });
    let newListing = res.data;
    navigate(`/listings/${newListing._id}`);
  };
  return (
    <div className="createListingPage">
      <form onSubmit={handleSubmit}>
        <h2>Part-Out Details</h2>

        <label>
          Pictures:{" "}
          {/* <input
            type="text"
            name="pictures"
            value={car.pictures}
            onChange={handleCarInputChange}
          /> */}
        </label>
        <br />
        <FileUpload handleChange={handleCarInputChange} urls={car.pictures} />

        <label className="input-box">
          Title:{" "}
          <input
            type="text"
            name="title"
            value={car.title}
            onChange={handleCarInputChange}
          />
        </label>
        <br />
        <label className="input-box">
          Price:{" "}
          <input
            type="number"
            name="price"
            value={car.price}
            onChange={handleCarInputChange}
          />
        </label>
        <br />
        <label className="input-box">
          Location:{" "}
          <input
            type="number"
            name="location"
            value={car.location}
            onChange={handleCarInputChange}
            required={true}
          />
        </label>

        <br />
        <p>
          <label>
            Year:
            <select onChange={handleCarInputChange} name="year">
              {years.toReversed().map((year) => {
                return <option value={year}>{year}</option>;
              })}
            </select>
          </label>
          <br />
          <label>
            Make:
            <select onChange={handleCarInputChange} name="make">
              {Object.keys(makesAndModels)
                .sort()
                .map((make) => {
                  return <option value={make}>{make}</option>;
                })}
            </select>
          </label>
          <br />
          <label>
            Model:
            <select onChange={handleCarInputChange} name="model">
              {makesAndModels[car.make].map((model) => {
                return <option value={model}>{model}</option>;
              })}
            </select>
          </label>
        </p>

        <br />
        <label className="input-box">
          Contact Email:{" "}
          <input
            type="text"
            name="contactEmail"
            value={car.contactEmail}
            onChange={handleCarInputChange}
            required={true}
          />
        </label>
        <br />

        <label className="description">
          Description:{" "}
          <textarea
            className="description"
            type="text"
            name="description"
            value={car.description}
            onChange={handleCarInputChange}
          />
        </label>
        <br />

        <h3>Add Parts:</h3>
        {parts.map((part, index) => (
          <div key={index}>
            <label>
              Price:{" "}
              <input
                type="number"
                name="price"
                value={part.price}
                onChange={(e) => handlePartInputChange(e, index)}
              />
            </label>

            <label>
              <p>
                Part Category:
                <select
                  onChange={(e) => handlePartInputChange(e, index)}
                  name="mainCategory"
                >
                  {Object.keys(partCategories).map((mainCat) => {
                    return (
                      <option
                        selected={part.mainCategory === mainCat}
                        value={mainCat}
                      >
                        {mainCat}
                      </option>
                    );
                  })}
                </select>
                <select
                  onChange={(e) => handlePartInputChange(e, index)}
                  name="subCategory"
                >
                  {partCategories[part.mainCategory].map((subCat) => {
                    return (
                      <option
                        selected={part.subCategory === subCat}
                        value={subCat}
                      >
                        {subCat}
                      </option>
                    );
                  })}
                </select>
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    removePart(index);
                  }}
                >
                  remove part
                </button>
              </p>
            </label>
          </div>
        ))}
        <button type="button" onClick={addPart}>
          Add Part
        </button>
        <br />
        <div className="submit">
          <button type="submit">Submit</button>
        </div>
      </form>
    </div>
  );
};

export default CreatePartsCarListing;
