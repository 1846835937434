import React, { useState } from "react";
import axiosInstance from "../../utils/axiosInstance";
import { Link, Navigate } from "react-router-dom";
import "./Signup.css";

const LoginForm = ({ setIsLoggedIn, isLoggedIn, setUsername }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axiosInstance.post("/user/login", {
        email,
        password,
      });
      localStorage.setItem("token", response.data.token);
      // Redirect or do something else after successful login
      setIsLoggedIn(true);
    } catch (error) {
      alert("wrong username or password");
    }
  };

  if (isLoggedIn) {
    return <Navigate to="listings" />;
  }

  return (
    <div className="sign-up">
      <form onSubmit={handleSubmit}>
        <h3>Login:</h3>
        <input
          className="inputBox"
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <br />
        <input
          className="inputBox"
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
        />
        <br />
        <Link className="forgot-password" to="/forgotPassword">
          forgot password
        </Link>
        <br />
        <div className="submit">
          <button type="submit">Login</button>
        </div>
      </form>
    </div>
  );
};

export default LoginForm;
