import React, { useState } from "react";
import axiosInstance from "../../utils/axiosInstance";
import { useNavigate } from "react-router-dom";
import "./Signup.css";

const SignupForm = () => {
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axiosInstance.post("/user/signup", { username, email, password });
      // Redirect or do something else after successful signup
      navigate(`/profilePage`);
    } catch (error) {
      console.error("Signup failed");
      alert('There is already an account with that email')
    }
  };

  return (
    <div className="sign-up">
      <form onSubmit={handleSubmit}>
        <h3>Sign-up:</h3>
        <input
          className="inputBox"
          type="text"
          placeholder="Username"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          required
        />
        <br />
        <input
          className="inputBox"
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <br />
        <input
          className="inputBox"
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
        />
        <br />
        <p>
          <input type="checkbox" required />
          By signing up I agree to the{" "}
          <a href="https://app.termly.io/document/terms-of-service/df5cb76d-40ae-45c2-aa39-71a3b60adff4">
            terms of service
          </a>{" "}
          and{" "}
          <a href="https://app.termly.io/document/privacy-policy/5877d090-0d73-4c6e-9331-8f81f9a7e147">
            privacy policy
          </a>
        </p>
        <div className="submit">
          <button type="submit">Sign Up</button>
        </div>
      </form>
    </div>
  );
};

export default SignupForm;
