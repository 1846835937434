import React from "react";
import "./contact.css";
import { Link } from "react-router-dom";
const Contact = () => {
  return (
    <div className="contact-page">
      <h2>Contact Us</h2>
      <iframe
        title="contact-form"
        src="https://docs.google.com/forms/d/e/1FAIpQLSdX25Ns-mFdI35TbEYzkA9QV2XzOfcb1Gb1oKC2PfNKM4w5kQ/viewform?embedded=true"
        width="640"
        height="1970"
        frameborder="0"
        marginheight="0"
        marginwidth="0">
        Loading…
      </iframe>
      <p> <Link to="/legal">Legal</Link></p>
    </div>
  );
};

export default Contact;
