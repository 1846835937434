import React, { useState } from "react";
import FileUpload from "../../components/FileUpload";
import axiosInstance from "../../utils/axiosInstance";

import partCategories from "../../FormOptionsJson/categories.js";
import years from "../../FormOptionsJson/years";
import { useNavigate } from "react-router-dom";
const CreateSinglePartListing = ({makesAndModels, userEmail}) => {
  const [part, setPart] = useState({
    title: "",
    description: "",
    location: "",
    pictures: [],
    price: 1234,
    mainCategory: Object.keys(partCategories)[0],
    subCategory: partCategories[Object.keys(partCategories)[0]][0],
    make: Object.keys(makesAndModels).sort()[0],
    model: makesAndModels["ACURA"][0],
    year: years[-1],
    type: "singlePart",
    contactEmail: userEmail,
  });

  const navigate = useNavigate();

  const handlePartInputChange = (e) => {
    if (e.target.name === "make") {
      setPart({
        ...part,
        [e.target.name]: e.target.value,
        model: makesAndModels[e.target.value][0],
      });
    } else if(e.target.name === "mainCategory"){
      setPart({
        ...part,
        [e.target.name]: e.target.value,
        subCategory: partCategories[e.target.value][0]
      })
    }else {
      setPart({
        ...part,
        [e.target.name]: e.target.value,
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // You can call an API here to save the car object in your database
    let res = await axiosInstance.post("/listings", {
      title: part.title,
      description: part.description,
      location: part.location,
      pictures: part.pictures,
      parts: [
        {
          price: part.price,
          mainCategory: part.mainCategory,
          subCategory: part.subCategory,
        },
      ],
      price: part.price,
      make: part.make,
      model: part.model,
      year: part.year,
      type: "singlePart",
      contactEmail: part.contactEmail,
      
    });
    let newListing = res.data
    navigate(`/listings/${newListing._id}`);
  };
  return (
    <div className="createListingPage">
      <form onSubmit={handleSubmit}>
        <h2>Part Details</h2>

        <label>
          Pictures:{" "}
          {/* <input
            type="text"
            name="pictures"
            value={part.pictures}
            onChange={handlePartInputChange}
          /> */}
        </label>
        <br />
        <FileUpload handleChange={handlePartInputChange} urls={part.pictures} />

        <label className='input-box'>
          Title:{" "}
          <input
            type="text"
            name="title"
            value={part.title}
            onChange={handlePartInputChange}
          />
        </label>
        <br />
        <label className='input-box'>
          Price:{" "}
          <input
            type="number"
            name="price"
            value={part.price}
            onChange={handlePartInputChange}
          />
        </label>
        <br />
        <label className='input-box'>
          Location:{" "}
          <input
            type="number"
            name="location"
            value={part.location}
            onChange={handlePartInputChange}
            required={true}
          />
        </label>

        <br /><p>
          <label>
            Year:
            <select onChange={handlePartInputChange} name="year">
              {years.toReversed().map((year) => {
                return <option value={year}>{year}</option>;
              })}
            </select>
          </label>
          <br />
          <label>
            Make:
            <select onChange={handlePartInputChange} name="make">
              {Object.keys(makesAndModels).sort().map((make) => {
                return <option value={make}>{make}</option>;
              })}
            </select>
          </label>
          <br />
          <label>
            Model:
            <select onChange={handlePartInputChange} name="model">
              {makesAndModels[part.make].map((model) => {
                return <option value={model}>{model}</option>;
              })}
            </select>
          </label></p>

        <br />
        <p><label>
          Part Category:
          <select onChange={handlePartInputChange} name="mainCategory">
            {Object.keys(partCategories).map((mainCat) => {
              return <option selected={part.mainCategory === mainCat} value={mainCat}>{mainCat}</option>;
            })}
          </select>
          <select onChange={handlePartInputChange} name="subCategory">
            {partCategories[part.mainCategory].map((subCat) => {
              return <option selected={part.subCategory === subCat} value={subCat}>{subCat}</option>;
            })}
          </select>
        </label></p>

        <br />
        <label className='input-box'>
          Contact Email:{" "}
          <input
            type="text"
            name="contactEmail"
            value={part.contactEmail}
            onChange={handlePartInputChange}
            required
          />
        </label>
        <br />

        <label className='description'>
          Description:{" "}
          <textarea className="description"
            type="text"
            name="description"
            value={part.description}
            onChange={handlePartInputChange}
          />
        </label>




        <div className="submit">
          <button type="submit">
            Submit
          </button></div>
      </form>
    </div>
  );
};

export default CreateSinglePartListing;
