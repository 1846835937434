import { Link, useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import axiosInstance from "../../utils/axiosInstance";
import Carousel from "../../components/Carousel";
import PartDetails from "./PartDetails";
import "./listingDetails.css";
import { storage } from "../../utils/firebase";
import { ref, deleteObject } from "firebase/storage";

const ListingDetails = ({ username }) => {
  const [listing, setListing] = useState({});
  const [isOwner, setIsOwner] = useState(false);
  const [saved, setSaved] = useState(false)
  let { listingId } = useParams();

  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      let res = await axiosInstance.get(
        `listings/${listingId}`
      );
      setListing(res.data.listing);
      setIsOwner(res.data.isOwner);
      setSaved(res.data.saved)
    };
    fetchData();
  }, [listingId]);

  let allImages = [];
  if (listing.pictures) {
    allImages = listing.pictures;
    
  }

  const handleDelete = async () => {
    let res = await axiosInstance.delete(`/listings/${listingId}`);
    if (res) {
      try {
        await res.data.pictures.forEach(async (pic) => {
          let imageRefToDelete = ref(storage, pic);

          await deleteObject(imageRefToDelete);
        });
      } catch (e) {
        console.log('something went wrong');
      }
      navigate("/listings");
    }
  };

  const handleSave = async (e) => {
    e.preventDefault()
    try {
      await axiosInstance.patch('/user/addSavedPost', {listingId})
      setSaved(true)
    } catch (e) {
      console.log('something went wrong')
    }
   
  }

  const handleUnsave = async (e) => {
    e.preventDefault()
    try {
      await axiosInstance.patch('/user/removeSavedPost', {listingId})
      setSaved(false)
    } catch (e) {
      console.log('something went wrong')
    }
    
  }
  console.log(listing)
  return (
    <div className={"listing-details"}>
      {listing.pictures ? (
        <>
          <Carousel images={allImages} />
          <h2>
            {listing.title}: ${listing.price}
          </h2>
          <p>{listing.cityState}</p>
          <p>{listing.description}</p>
          {listing.contactEmail !== "" ? (
            <p>contact email: {listing.contactEmail}</p>
          ) : (
            <p>
              Please <Link to="/login">Log in</Link> to view contact info
            </p>
          )}
          <div>
            {listing.type === "singlePart" ? "" : <>
            {listing.parts.length > 0 ? <h3>Parts Available</h3> : ""}
            {listing.parts.map((part) => {
              return <PartDetails part={part} />;
            })}
            </>}
            
            
          </div>
          <div>
          {isOwner ? <Link className="edit-link" to={`/edit/${listingId}`}>Edit Listing</Link> : ""}
          {isOwner ? (
            <button className="details-button"onClick={handleDelete}>Delete Listing</button>
          ) : (
            ""
          )}          
          {listing.contactEmail !=="" ? (!saved) ? (
            <button className="details-button" onClick={handleSave}>Save This Post</button>
          ) : (
            <button className="details-button" onClick={handleUnsave}>Unsave This Post</button>
          ): ""}
          </div>
         

          
        </>
      ) : (
        "loading..."
      )}
    </div>
  );
};

export default ListingDetails;
