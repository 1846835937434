import React from "react";
import { Route, Navigate, Router } from "react-router-dom";

const ProtectedRoute = ({ component: Component, isLoggedIn, ...rest }) => {
  return (
        
   isLoggedIn ? <Component {...rest} /> : <Navigate to="/login" />
 

  );
};

export default ProtectedRoute;
