import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom'; 
import axiosInstance from '../../utils/axiosInstance';
import ListingCard from '../listings/ListingCard';
import './profilePage.css'


const SavedListings = ({savedListings}) => {
  return (
    <div className='yourListings'>
    {savedListings.length > 0 ? savedListings.map((listing,index) => {
          return  <ListingCard key={index} listing={listing} />
        }) : <p>It looks like you do not have any saved listings. Check out <Link to="/listings">all listings</Link> to find some</p>}
    </div>
  );
};

export default SavedListings;