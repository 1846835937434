

import { Link } from "react-router-dom";
import "./listing.css";

const ListingCard = ({ listing }) => {
  return (
    <Link style={{height: "fit-content"}} to={`/listings/${listing._id}`}>
      <div className="listing-card">
        <img src={listing.pictures[0]} alt="Product" />
        <div className="price">${listing.price}</div>
        <div className="title">{listing.title}</div>
        <p className="title">{listing.cityState}</p>
      </div>
    </Link>
  );
};

export default ListingCard;
