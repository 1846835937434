import React, { useEffect, useState } from "react";
import CreatePartsCarListing from "./CreatePartsCarListing";
import CreateCompleteCarListing from "./CreateCompleteCarListing.js";
import CreateSinglePartListing from "./CreateSinglePartListing.js";
import { Link } from "react-router-dom";
import './createListing.css'
import axiosInstance from "../../utils/axiosInstance.js";

const CreateListing = ({ isLoggedIn,makesAndModels }) => {
  const [listingType, setListingType] = useState("");
  const [userEmail, setUserEmail] = useState("");
  useEffect(() => {
    const getUserEmail = async () => {
      let res = await axiosInstance.get('/user/userInfo')
      let user = res.data;
      setUserEmail(user.email);
    }

    getUserEmail()
  },[])
  return isLoggedIn ? (
    <div className="createListingPrompt">
      <h2>What would you like to list?</h2>

      <button
        onClick={(e) => {
          setListingType("partsCar");
        }}
        style={
          listingType === "partsCar" ? { border: "thick inset black" } : {}
        }
      >
        Vehicle Part-Out
      </button>
      <button
        onClick={(e) => {
          setListingType("completeCar");
        }}
        style={
          listingType === "completeCar" ? { border: "thick inset black" } : {}
        }
      >
        Complete Car/Truck
      </button>
      <button
        onClick={(e) => {
          setListingType("singlePart");
        }}
        style={
          listingType === "singlePart" ? { border: "thick inset black" } : {} 
        }
      >
        Part/Accessory
      </button>

      {listingType === "partsCar" ? <CreatePartsCarListing userEmail={userEmail} makesAndModels={makesAndModels}/> : ""}
      {listingType === "completeCar" ? <CreateCompleteCarListing userEmail={userEmail} makesAndModels={makesAndModels}/> : ""}
      {listingType === "singlePart" ? <CreateSinglePartListing userEmail={userEmail} makesAndModels={makesAndModels}/> : ""}
    </div>
  ) : (
    <div>
      <p>Please <Link to="/login">Login</Link> or <Link to="/signup">Signup</Link> to create a listing</p>
    </div>
  );
};

export default CreateListing;
