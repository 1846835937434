import {  useEffect, useState } from "react";
import "./listingsFilter.css";
// import makesAndModels from "../../FormOptionsJson/makesAndModels";
import years from "../../FormOptionsJson/years";
import categories from "../../FormOptionsJson/categories";

const ListingFilter = ({ handleFilterListings, makesAndModels }) => {
  const [makes, setMakes] = useState([]);
  const [models, setModels] = useState([]);
  const [startYear, setStartYear] = useState(0);
  const [endYear, setEndYear] = useState(0);
  const [minPrice, setMinPrice] = useState(0);
  const [maxPrice, setMaxPrice] = useState(Infinity);
  const [mainCategory, setMainCategory] = useState([]);
  const [subCategory, setSubCategory] = useState([]);
  const [showModelsFilter, setShowModelsFilter] = useState(false);
  const [showMainCatFilter, setShowMainCatFilter] = useState(false);
  // const [showSubCatFilter, setShowSubCatFilter] = useState(true);

  const handleMakesChange = (e) => {
    if (e.target.checked) {
      setMakes([...makes, e.target.value]);
    } else {
      let newMakes = makes.filter((make) => make !== e.target.value);
      let newModels = models.filter((model) => {
        return !makesAndModels[e.target.value].includes(model);
      });
      setMakes(newMakes);
      setModels(newModels);
    }
  };

  const handleModelsChange = (e) => {
    if (e.target.checked) {
      setModels([...models, e.target.value]);
    } else {
      let newModels = models.filter((model) => model !== e.target.value);
      setModels(newModels);
    }
  };

  const handleMainCategoryChange = (e) => {
    if (e.target.checked) {
      setMainCategory([...mainCategory, e.target.value]);
    } else {
      let newMainCategories = mainCategory.filter(
        (mainCat) => mainCat !== e.target.value
      );
      let newSubCategories = subCategory.filter((subCat) => {
        return !categories[e.target.value].includes(subCat);
      });
      setMainCategory(newMainCategories);
      setSubCategory(newSubCategories);
    }
  };

  const handleSubCategoryChange = (e) => {
    if (e.target.checked) {
      setSubCategory([...subCategory, e.target.value]);
    } else {
      let newSubCategories = subCategory.filter(
        (subCat) => subCat !== e.target.value
      );
      setSubCategory(newSubCategories);
    }
  };

  const clearFilters = () => {
    setMakes([]);
    setModels([]);
    setStartYear(0);
    setEndYear(0);
    setMinPrice(0);
    setMaxPrice(Infinity);
    setMainCategory([]);
    setSubCategory([]);

    handleFilterListings([], [], 0, 0, 0, Infinity, [], []);
  };

  useEffect(() => {
    const setFilters = () => {
      console.log("setting filters");
      let sessionMakes = JSON.parse(sessionStorage.getItem("makes"));
      let makes = sessionMakes ? sessionMakes : []
      console.log('makes',makes);
      if (makes) {
        setMakes(makes);
      }
      
      let sessionModels = JSON.parse(sessionStorage.getItem("models"));
      let models = sessionModels ? sessionModels : []
      console.log('models',models);
      if (models) {
        setModels(models);
      }

      let sessionStartYear = JSON.parse(sessionStorage.getItem("startYear"));
      let startYear = sessionStartYear ? sessionStartYear : 0
      console.log('startYear',startYear);
      if (startYear) {
        setStartYear(startYear);
      }

      let sessionEndYear = JSON.parse(sessionStorage.getItem("endYear"));
      let endYear = sessionEndYear ? sessionEndYear : 0
      console.log('endYear',endYear);
      if (endYear) {
        setEndYear(endYear);
      }
      let sessionMinPrice = JSON.parse(sessionStorage.getItem("minPrice"));
      let minPrice = sessionMinPrice ? sessionMinPrice : 0
      console.log('minPrice',minPrice);
      if (minPrice) {
        setMinPrice(minPrice);
      }
      let sessionMaxPrice = JSON.parse(sessionStorage.getItem("maxPrice"));
      let maxPrice = sessionMaxPrice ? sessionMaxPrice : Infinity
      console.log('maxPrice',maxPrice);
      if (maxPrice) {
        setMaxPrice(maxPrice);
      }
      let sessionMainCategory = JSON.parse(sessionStorage.getItem("mainCategory"));
      let mainCategory = sessionMainCategory ? sessionMainCategory : []
      console.log('mainCategory',mainCategory);
      if (mainCategory) {
        setMainCategory(mainCategory);
      }
      let sessionSubCategory = JSON.parse(sessionStorage.getItem("subCategory"));
      let subCategory = sessionSubCategory ? sessionSubCategory : []
      console.log('subCategory',subCategory);
      if (subCategory) {
        setSubCategory(subCategory);
      }

      handleFilterListings(
        makes,
        models,
        startYear,
        endYear,
        minPrice,
        maxPrice,
        mainCategory,
        subCategory
      );
    };
    setFilters();
    
  }, [handleFilterListings]);

  console.log(makes);

  return (
    <div className="listings-filter">
      <h1>Filter Listings</h1>
      <p
        className="show-models"
        onClick={() => setShowModelsFilter(!showModelsFilter)}
      >
        Show Makes/Models
      </p>
      <div className={showModelsFilter ? "" : "makes-models-filter-closed"}>
        {Object.keys(makesAndModels)
          .sort()
          .map((make) => {
            let modelsCheckboxes = "";
            if (makes.includes(make)) {
              modelsCheckboxes = makesAndModels[make].map((model) => {
                return (
                  <label key={model}>
                    {model}
                    <input
                      onChange={handleModelsChange}
                      type="checkbox"
                      name="model"
                      value={model}
                      checked={models.includes(model)}
                    />
                  </label>
                );
              });
            }

            return (
              <div>
                <label key={make}>
                  {make}
                  <input
                    onChange={handleMakesChange}
                    type="checkbox"
                    name="make"
                    value={make}
                    checked={makes.includes(make)}
                  />
                </label>
                <div className="modelsContainer">{modelsCheckboxes}</div>
              </div>
            );
          })}
      </div>
      <div className="Filter-Year-Price">
      <div>
        <label>
          Start Year:
          <select onChange={(e) => setStartYear(e.target.value)} name="year">
            <option value={0}></option>
            {years.toReversed().map((year) => {
              return (
                <option selected={startYear === year} value={year}>
                  {year}
                </option>
              );
            })}
          </select>
        </label>
      </div>
      <div>
        <label>
          End Year:
          <select onChange={(e) => setEndYear(e.target.value)} name="year">
            <option value={Infinity}></option>
            {years.toReversed().map((year) => {
              return (
                <option selected={endYear === year} value={year}>
                  {year}
                </option>
              );
            })}
          </select>
        </label>
      </div>

      <div>
        <label>
          Minimum Price:
          <input type="number" value={minPrice? minPrice: ""}onChange={(e) => setMinPrice(e.target.value)} />
        </label>
      </div>

      <div>
        <label>
          Maximum Price:
          <input type="number" value={maxPrice} onChange={(e) => setMaxPrice(e.target.value)} />
        </label>
      </div>
      </div>
      <p
        onClick={() => setShowMainCatFilter(!showMainCatFilter)}
        className="show-models"
      >
        Show/Hide Categories
      </p>
      <div className={showMainCatFilter ? "" : "makes-models-filter-closed"}>
        {Object.keys(categories)
          .sort()
          .map((mainCat) => {
            let subCatCheckboxes = "";
            if (mainCategory.includes(mainCat)) {
              subCatCheckboxes = categories[mainCat].map((subCat) => {
                return (
                  <label key={subCat}>
                    {subCat}
                    <input
                      onChange={handleSubCategoryChange}
                      type="checkbox"
                      name="subCategory"
                      value={subCat}
                      checked={subCategory.includes(subCat)}
                    />
                  </label>
                );
              });
            }

            return (
              <div>
                <label key={mainCat}>
                  {mainCat}
                  <input
                    onChange={handleMainCategoryChange}
                    type="checkbox"
                    name="mainCategory"
                    value={mainCat}
                    checked={mainCategory.includes(mainCat)}
                  />
                </label>
                <div className="modelsContainer">{subCatCheckboxes}</div>
              </div>
            );
          })}
      </div>

      <div></div>
      <button
        onClick={(e) => {
          e.preventDefault();
          handleFilterListings(
            makes,
            models,
            startYear,
            endYear,
            minPrice,
            maxPrice,
            mainCategory,
            subCategory
          );
        }}
      >
        Apply Filter
      </button>
      <button
        onClick={(e) => {
          e.preventDefault();
          clearFilters();
        }}
      >
        Clear Filter
      </button>
    </div>
  );
};

export default ListingFilter;
