import React, { useEffect, useState } from "react";
import { storage } from "../utils/firebase.js";
import {
  ref,
  uploadBytes,
  getDownloadURL,
  deleteObject,
} from "firebase/storage";
import { v4 } from "uuid";
import "./fileUpload.css";

const UploadImage = ({ handleChange, urls = [] }) => {
  const [images, setImages] = useState([]);

  const handleImageChange = (e) => {
    console.log(e.target.files);
    if (e.target.files) {
      setImages(e.target.files);
    }
  };

  const handleUpload = async (e) => {
    e.preventDefault();
    console.log('uploading', images)
    if (images.length < 0) {
      return;
    }

    for(let i = 0; i < images.length; i++){
      console.log(images[i])
      const imageRef = ref(storage, `images/${images[i].name + v4()}`);

      let res = await uploadBytes(imageRef, images[i]);
      console.log(res)
      let newUrl = await getDownloadURL(ref(storage, res.metadata.fullPath));
      console.log(newUrl)
      urls = [...urls, newUrl]
      console.log(urls)
      handleChange({ target: { name: "pictures", value: urls } });
      
    };
  };

  const handleDeleteImage = async (urlToDelete) => {
    let imageRefToDelete = ref(storage, urlToDelete);
    try {
      await deleteObject(imageRefToDelete);
      let newUrls = urls.filter((url) => {
        return url !== urlToDelete;
      });
      handleChange({ target: { name: "pictures", value: newUrls } });
    } catch (e) {
      console.log("something went wrong");
    }
  };
  return (
    <div>
      <input type="file" multiple onChange={handleImageChange} />
      <button onClick={handleUpload}>Upload</button>
      <div className="image-uploads">
        {urls.map((url, index) => {
          return (
            <div key={index}>
              <img src={`${url}`} alt={url} />{" "}
              <button
                onClick={(e) => {
                  e.preventDefault();
                  handleDeleteImage(url);
                }}
              >
                Remove Image
              </button>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default UploadImage;
