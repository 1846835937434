
const PartDetails = ({part}) => {
  return (
    <div className={'part-details'}>
        <h2>{part.subCategory}: ${part.price}</h2>
        <p>{part.description}</p>
        <p>{part.mainCategory}</p>
    </div>
  );
};

export default PartDetails;