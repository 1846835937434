import React from "react";
import { Link } from "react-router-dom";
import "./NavBar.css"

const Navbar = ({ isLoggedIn, handleSignOut }) => {
  return (
    <nav>
      <ul className="banner">
        <li className="logo-pic">
          <Link to="/"><img className="logo-pic" src="/Thank (1).png" alt="home page logo"/></Link>
        </li>
        <ul className="linksContainer">
          
        {!isLoggedIn ? (
          <>
            <li className="nav-links">
              <Link className="link" to="/login">Login</Link>
            </li>
            <li className="nav-links">
              <Link className="link" to="/signup">Sign-up</Link>
            </li>
          </>
        ) : (
          <>
           <li className="nav-links" onClick={handleSignOut}>
            Sign Out
          </li>
          <li className="nav-links">
          <Link className="link" to="profilePage">Profile Page</Link>
        </li>
          </>
         
        )}
        <li className="nav-links">
          <Link className="link" to="create-listing">Create Listing</Link>
        </li>
        <li className="nav-links"><Link className="link" to={'/contact'}>Contact Us</Link></li>
        </ul>
       
      </ul>
    </nav>
  );
};

export default Navbar;
