import React, { useState } from 'react';
import './searchBar.css';

const SearchBar = ({ onSearch }) => {
  // const [searchTerm, setSearchTerm] = useState('');
  const [zipcode, setZipcode] = useState('');
  const [distance, setDistance] = useState('');

  const handleSearch = (e) => {
    e.preventDefault();
    onSearch({ zipcode, distance });
  };

  return (
    <form className="search-bar" onSubmit={handleSearch}>
      {/* <input
        type="text"
        placeholder="Search"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
      /> */}
      <input
        type="text"
        placeholder="Zipcode"
        maxLength="5"
        value={zipcode}
        onChange={(e) => setZipcode(e.target.value)}
      />
      <select value={distance} onChange={(e) => setDistance(e.target.value)}>
        <option value="">Any Distance</option>
        <option value="20">20 miles</option>
        <option value="60">60 miles</option>
        <option value="150">150 miles</option>
        <option value="500">500 miles</option>
      </select>
      <button type="submit">Search</button>
    </form>
  );
};

export default SearchBar;
