import axios from 'axios';

const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API, // Change this to your backend URL
    timeout: 5000,
    headers: {
        'Content-Type': 'application/json'
    }
});


// Add a request interceptor to include the authorization header
axiosInstance.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem('token');
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

export default axiosInstance;
