import React, { useState, useEffect } from "react";
import FileUpload from "../../components/FileUpload";
import axiosInstance from "../../utils/axiosInstance";

import years from '../../FormOptionsJson/years'
import { useNavigate } from "react-router-dom";
const EditCompleteCarListing = ({listing, makesAndModels}) => {
    const [car, setCar] = useState({
      title: "",
      description: "",
      location: "",
      parts: [],
      pictures: [],
      price: "",
      make: Object.keys(makesAndModels)[0],
      model: makesAndModels[Object.keys(makesAndModels)[0]][0],
      year: years[-1],
      type: "completeCar",
      contactEmail: '',
    });
  
    useEffect(() => {
      setCar(listing)
      
    }, [listing])
    const navigate = useNavigate();
    const handleCarInputChange = (e) => {
    
        if(e.target.name === 'make'){
            setCar({
                ...car,
                [e.target.name]: e.target.value,
                model: makesAndModels[e.target.value][0],
              });
        } else {
            setCar({
                ...car,
                [e.target.name]: e.target.value,
              });
        }
        
      };
    
  
    const handleSubmit = async (e) => {
      e.preventDefault();
      
      // You can call an API here to save the car object in your database
      
      let res = await axiosInstance.put(`/listings/${listing._id}`, car)
      let newListing = res.data
      navigate(`/listings/${newListing._id}`)
    };
   
    return (
      <div className="createListingPage">
      <form onSubmit={handleSubmit}>
        <h2>Vehicle Details</h2>

        <label>
          Pictures:{" "}
          {/* <input
            type="text"
            name="pictures"
            value={car.pictures}
            onChange={handleCarInputChange}
          /> */}
        </label>
        <br />
        <FileUpload handleChange={handleCarInputChange} urls={car.pictures} />

        <label className='input-box'>
          Title:{" "}
          <input
            type="text"
            name="title"
            value={car.title}
            onChange={handleCarInputChange}
          />
        </label>
        <br />
        <label className='input-box'>
          Price:{" "}
          <input
            type="number"
            name="price"
            value={car.price}
            onChange={handleCarInputChange}
          />
        </label>
        <br />
        <label className='input-box'>
          Location:{" "}
          <input
            type="number"
            name="zipcode"
            value={car.zipcode}
            onChange={handleCarInputChange}
          />
        </label>

        <br /><p>
          <label>
            Year
            <select onChange={handleCarInputChange} name="year">
              {years.toReversed().map((year) => {
                return <option selected={car.year===parseInt(year)} value={year}>{year}</option>;
              })}
            </select>
          </label>
          <label>
            Make
            <select onChange={handleCarInputChange} name="make">
              {Object.keys(makesAndModels).map((make) => {
                return <option selected={car.make===make}value={make}>{make}</option>;
              })}
            </select>
          </label>
          <label>
            Model
            <select onChange={handleCarInputChange} name="model">
              {makesAndModels[car.make].map((model) => {
                return <option selected={car.model===model}value={model}>{model}</option>;
              })}
            </select>
          </label></p>

        <br />
        <label className='input-box'>
          Contact Email:{" "}
          <input
            type="text"
            name="contactEmail"
            value={car.contactEmail}
            onChange={handleCarInputChange}
          />
        </label>
        <br />

        <label className='description'>
          Description:{" "}
          <textarea className="description"
            type="text"
            name="description"
            value={car.description}
            onChange={handleCarInputChange}
          />
        </label>

        <br />
        <div className="submit">
        <button type="button" onClick={handleSubmit}>Submit</button>
        </div>
      </form>


    </div>
    );
  };
  
  export default EditCompleteCarListing;